<template>
  <div class="box col-wrapper">
    <div style="align-self: stretch">
      <el-button @click="$router.go(-1)">返回</el-button>
    </div>
    <div class="row-wrapper form">
      <el-form class="left-form" label-width="80px">
        <el-form-item label="地区">
          <el-cascader
              ref="districtSelect"
              v-model="form.area"
              :options="options"
              :props="{expandTrigger: 'hover',checkStrictly: true}"
              clearable>
            <template #default="{node,data}">
              <div @click="selectArea(node)">
                <span>{{ data.label }}</span>
              </div>
            </template>
          </el-cascader>
        </el-form-item>
        <el-form-item label="类别">
          <el-cascader
              v-model="form.categoryData"
              :options="categoryList"
              @change="selectCategory"
              :props="categoryProps">
            <template #default="{node,data}">
              <div @click="selectCategory(node)">
                <span>{{ data.name }}</span>
              </div>
            </template>
          </el-cascader>
        </el-form-item>
        <el-form-item label="更改封面">
          <el-upload
              ref="cover"
              action="#"
              :before-upload="beforeUploadCover"
              :file-list="coverUrl"
              list-type="picture-card"
              :limit="1"
              accept=".png,.jpeg,.jpg"
              :on-exceed="uploadCover"
              :auto-upload="true">
            <template #trigger>
              <el-button type="primary">选择封面</el-button>
            </template>
            <template #file="{file}">
              <img :src="file.url" alt="封面"/>
            </template>
          </el-upload>
        </el-form-item>
      </el-form>
      <el-form class="right-form" label-width="120px">
        <el-form-item label="基地">
          <el-select v-model="form.base" placeholder="请先选择地区" class="base">
            <el-option
                v-for="item in baseList"
                :key="item.id"
                :label="item.name"
                :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="标题">
          <el-input v-model="form.title"/>
        </el-form-item>
        <el-form-item label="上传音视频">
          <el-upload
              ref="mp3"
              :file-list="audioUid"
              action="#"
              :limit="10"
              :on-exceed="limitResouce"
              :show-file-list="showFileList"
              accept=".mp3,.mp4,.aac"
              :http-request="uploadAudio"
              :on-remove="removeAudio"
              :multiple="true">
            <template #trigger>
              <el-button type="primary">选择文件</el-button>
            </template>
            <el-progress :percentage="uploadPercentage" v-show="showPrograss"/>
          </el-upload>
        </el-form-item>
      </el-form>
    </div>
    <div style="width: 65vw">
      <el-form>
        <el-form-item label="内容">
          <BasicEditor
              @editOnChange="editOnChange"
              :html="editText"
              v-model="form.text"></BasicEditor>
        </el-form-item>
      </el-form>
    </div>
    <Button style="width: 180px"
            @click="ReleaseResources"
            :type="'primary'">修改
    </Button>
  </div>
</template>
<script>
import * as ResourcesApi from "../../api/api/Resources"
import request from "../../api/axios";
import {AXIOS_BASE_URL} from "@/api/config";

export default {
  name: "Index",
  data() {
    return {
      form: {
        id: undefined,
        area: [],
        areaNames: [],
        base: undefined,
        category: undefined,
        categoryData: [],
        title: "",
        text: "",
        cover: "",
        cover_id: undefined,
        resource_id: []
      },
      editText: "",
      audioUid: [],
      areaData: require("../../assets/json/ssq.json"),
      baseList: [], // 基地列表
      categoryList: [], // 类别
      categoryProps: {
        value: "id",
        label: "name",
        children: "childItem",
        checkStrictly: true,
      },
      // --- 文件上传 ---
      showFileList: true, // 音频文件列表是否显示
      coverUrl: [],
      audioUrls: [], // 音视频文件地址
      showPrograss: false, // 音频文件上传进度条
      uploadPercentage: 0, // 音频文件上传进度
      percentageInterval: null,
    }
  },
  activated() {
    // this.$store.commit("setIndexPath", ["发布", "文章修改"])
    this.GetArticleForId(this.$route.query.id)
    this.GetCategoryList()
    this.GetBaseList()
  },
  computed: {
    /**
     * 行政区选择组件配置
     * @return {{}}
     */
    options() {
      return this.areaData.province
    }
  },
  methods: {
    // --- 事件 ---
    /**
     * 选择类型
     * @param val node节点
     */
    selectCategory(val) {
      this.form.category = val.value
      this.form.categoryData = val.pathValues
      console.log(this.form)
    },
    /**
     * 选择区域
     */
    selectArea(val) {
      this.form.area = val.pathValues
      this.form.areaNames = val.pathLabels
      this.GetBaseList()
    },
    /**
     * 限制音视频文件数量
     */
    limitResouce() {
      this.$message.warning("音视频文件上传数量不超过10个")
    },
    /**
     * 富文本编辑器
     * @param val
     */
    editOnChange(val) {
      this.form.text = val
    },
    /**
     * 封面上传 文件上传前的钩子 用于第一次上传
     * @param val
     */
    beforeUploadCover(val) {
      this.$refs.cover.handleStart(val)
      this.UploadResources("{type:03}", [val])
    },
    /**
     * 封面更改
     * @param val
     */
    uploadCover(val) {
      this.$refs.cover.clearFiles()
      this.$refs.cover.handleStart(val[0])
      this.UploadResources("{type:03}", val)
    },
    /**
     * 移除音频文件
     */
    removeAudio(file) {
      let index = this.form.resource_id.indexOf(file.id)
      this.form.resource_id.splice(index, 1)
    },
    //   --- axios ---
    /**
     * 上传音 视频文件 获取上传进度
     * @param val
     */
    uploadAudio(val) {
      const fileType = val.file.type.split("/")[0] === "audio" ? "{type:01}" : "{type:02}"
      const fileFormat = val.file.name.split(".")[1]
      console.log(fileFormat)
      const whiteList = ["mp3", "mp4", "aac"] // 格式白名单
      if (whiteList.indexOf(fileFormat) === -1) {
        this.$refs.mp3.handleRemove(val)
        this.$message.error("只支持" + whiteList.join(",") + "格式!")
        return ''
      }
      let pd = new FormData()
      pd.append("text", fileType)
      pd.append("Files", val.file)
      let that = this
      this.showFileList = false
      request({
        url: "rest/Resources/UploadResources",
        method: "post",
        data: pd,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => { // 文件上传进度
          that.showPrograss = true
          that.uploadPercentage = Number((progressEvent.loaded / progressEvent.total * 100).toFixed(2))
        }
      })
          .then(res => {
            this.showFileList = true
            if (res.data.code === 200) {
              this.form.resource_id.push(res.data.data[0].id)
              this.uploadPercentage = 0
              this.showPrograss = false
              this.$message.success(res.data.message)
            } else {
              this.$message.error(res.data.message)
            }
          })
          .catch(err => {
            this.$message.error(err)
          })
    },
    /**
     * 上传文件
     * @param type 资源类型（01音频，02视频，03图片） 示例：{type:03}
     * @param files 文件
     * @constructor
     */
    UploadResources(type, files) {
      let pd = new FormData()
      pd.append("text", type)
      pd.append("Files", files[0])
      ResourcesApi.UploadResources(pd)
          .then(res => {
            if (res.data.code === 200) {
              this.$message.success("上传成功")
              if (type === "{type:03}") {
                this.form.cover = res.data.data[0].path
                this.form.cover_id = res.data.data[0].id
              }
            } else {
              this.$message.error(res.data.message)
            }
          })
          .catch(err => {
            console.error(err)
          })
    },
    /**
     * 获取基地列表
     * @constructor
     */
    GetBaseList() {
      const pd = {
        type: this.form.area.length - 1, // 0 - 省 1 - 市 2 - 区
        regionCode: this.form.area.length === 0 ? "" : this.form.area[this.form.area.length - 1]
      }
      ResourcesApi.GetBaseList(pd)
          .then(res => {
            if (res.data.code === 200) {
              this.baseList = res.data.data
              if (res.data.data.length > 0) {
                // this.form.base = res.data.data[0].id
              } else {
                this.$message.error("无基地数据")
                // this.form.base = undefined
              }
            } else {
              this.$message.error(res.data.message)
            }
          })
          .catch(err => {
            console.error(err)
            this.$message.error("请求失败")
          })
    },
    /**
     * 获取分类
     * @constructor
     */
    GetCategoryList() {
      const pd = {source: 1}
      ResourcesApi.GetCategoryList(pd)
          .then(res => {
            if (res.data.code === 200) {
              this.categoryList = res.data.data
            } else {
              this.$message.error(res.data.message)
            }
          })
          .catch(err => {
            this.$message.error(err)
          })
    },
    /**
     * 发布资源
     * @constructor
     */
    ReleaseResources() {
      const pd = {
        id: this.form.id,
        title: this.form.title,
        province_code: this.form.area[0],
        province: this.form.areaNames[0],
        city_code: this.form.area[1],
        city: this.form.areaNames[1],
        district_code: this.form.area[2],
        district: this.form.areaNames[2],
        base_id: this.form.base,
        category_id: this.form.category,
        content_detail: this.form.text,
        cover_id: this.form.cover_id,
        cover_url: this.form.cover,
        resource_id: this.form.resource_id.join(",")
      }
      ResourcesApi.ReleaseResources(pd)
          .then(res => {
            if (res.data.code === 200) {
              this.$message.success(res.data.message)
              this.$router.push({path: "/history"})
            } else {
              this.$message.error(res.data.message)
            }
          })
    },
    /**
     * 通过文章id获取文章详情
     * @param id 文章id
     * @constructor
     */
    GetArticleForId(id) {
      const pd = {id}
      ResourcesApi.GetArticleForId(pd)
          .then(res => {
            if (res.data.code === 200) {
              // --- 文章详情 ---
              const article = res.data.data.article
              this.form.title = article.title
              this.form.base = parseInt(article.base_id)
              this.form.category = parseInt(article.category_id)
              this.form.categoryData = article.category_id
              // --- 省市区
              let area = []
              area.push(article.province_code)
              if (article.city_code !== null) {
                area.push(article.city_code)
              }
              if (article.district_code !== null) {
                area.push(article.district_code)
              }
              this.form.areaNames.push(article.province)
              this.form.areaNames.push(article.city)
              this.form.areaNames.push(article.district)
              this.form.area = area
              // --- 其他 ---
              this.form.text = article.content_detail
              this.editText = article.content_detail
              this.form.cover = article.cover_url
              this.form.cover_id = article.cover_id
              this.form.id = article.id
              this.coverUrl = []
              this.coverUrl.push({name: "cover", url: AXIOS_BASE_URL + article.cover_url})
              // --- 音视频文件初始化 ---
              this.form.resource_id = []
              this.audioUid = []
              for (let i = 0; i < res.data.data.resource.length; i++) {
                const resourceInfo = res.data.data.resource[i]
                this.form.resource_id.push(resourceInfo.id)
                this.audioUid.push({
                  name: resourceInfo.name,
                  id: resourceInfo.id,
                  url: AXIOS_BASE_URL + resourceInfo.path
                })
              }
            } else {
              this.$message.error(res.data.message)
            }
          })
          .catch(err => {
            console.error(err)
            this.$message.error("获取文章信息失败")
          })
    }
  },
}
</script>

<style>
.el-radio {
  display: none;
}
</style>
<style scoped lang="less">
.box {
  .form {
    width: 58vw;
    justify-content: space-between;
    align-items: flex-start;

    ::v-deep(.el-form-item__content) {
      z-index: 10;
    }

    ::v-deep(.el-cascader ) {
      width: 100%;
    }

    /*/deep/ .el-select {*/

    ::v-deep(.el-select) {
      width: 100%;
    }

    /*/deep/ .el-input {*/

    ::v-deep(.el-input) {
      width: 100%;
    }

    .left-form {
      width: 51%;
    }

    .right-form {
      width: 49%;

      ::v-deep(.el-form-item__content) {
        flex-direction: column;
        align-items: stretch;
      }

      ::v-deep(.el-upload-list) {
        max-height: 13vh;
        overflow-y: scroll;
        overflow-x: hidden;
        width: 100%;
        //border: 1px solid var(--el-border-color);
        border-radius: var(--el-border-radius-base);
      }

      ::v-deep(.el-upload-list::-webkit-scrollbar ) {
        width: 10px;
      }

      ::v-deep(.el-upload-list::-webkit-scrollbar-thumb) {
        border-radius: 10px;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: #409eff;
      }
    }
  }
}
</style>